import React from "react"
import { Col, message, Row } from "antd"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import Template from "../../layouts/base"
import { navigate, useIntl } from "gatsby-plugin-intl"
import withAuth from "../../components/withAuthHoc"
import UserForm from "../../components/user/userForm"
import authFetch from "../../services/network"
import useStateObject from "../../hooks/useStateObject"

const UserNew = () => {
  const intl = useIntl()

  const [state, setState] = useStateObject({
    user: {
      name: "",
      last_name: "",
      email: "",
      roles: [],
    },
    processing: false,
  })

  const goBack = () => {
    navigate("/user")
  }

  const parseUserToCreate = user => {
    return {
      name: user.name,
      last_name: user.last_name,
      email: user.email,
      roles: user.roles.length > 0 ? user.roles : [],
    }
  }

  const createUser = user => {
    setState({ processing: true })
    authFetch(`${process.env.GATSBY_CONF_API_URL}/users`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: parseUserToCreate(user),
    })
      .then(response => {
        setState({ processing: false })
        if (201 !== response.status) {
          if (response.status === 409) {
            message.error(
              intl.formatMessage({
                id: "page-user-create-already-exists-error-text",
              })
            )
          } else {
            message.error(
              intl.formatMessage({
                id: "page-user-create-error-text",
              })
            )
          }
        } else {
          message.success(
            intl.formatMessage({
              id: "page-user-create-ok-text",
            })
          )
          response.json().then(user => {
            navigate(`/user/${user.id}`)
          })
        }
      })
      .catch(() => {
        setState({ processing: false })
        message.error(
          intl.formatMessage({
            id: "page-user-create-error-text",
          })
        )
      })
  }

  return (
    <Template selected={["user", "user-new"]}>
      <CustomBreadcrumb
        crumbs={[
          {
            label: intl.formatMessage({ id: "menu-user-management" }),
            link: "/user",
          },
          intl.formatMessage({ id: "menu-user-new" }),
        ]}
      />
      <Row>
        <Col className="intro">
          <h1>
            {intl.formatMessage({ id: "page-user-create-user-button-text" })}
          </h1>
        </Col>
      </Row>
      <Row>
        <div className="content">
          <UserForm
            user={state.user}
            isEditView={false}
            onCancel={goBack}
            onSubmit={createUser}
            processing={state.processing}
          />
        </div>
      </Row>
    </Template>
  )
}

export default withAuth(UserNew)
